import intersection from 'lodash/intersection';
import isEqual from 'lodash/isEqual';
import arrayConcat from 'lodash/concat';
import uniqBy from 'lodash/uniqBy';
import { shortFeatureNames, shortAccess, access as featureAccess } from './FeatureTypes';

const validatePermissions = ({ feature, locationId = null, location_id = null, access = null, permissions }) => {
  try {
    if (!permissions) {
      return false;
    }

    if (permissions?.su) {
      return true;
    }

    feature = shortFeatureNames[feature];
    locationId = locationId || location_id;

    if (!permissions[feature]) {
      return false;
    }

    if (!access) {
      access = featureAccess.CAN_VIEW;
    }

    permissions = permissions[feature];

    if (access === 'canView' || access === 'canAdd' || access === 'canEdit' || access === 'canDelete') {
    } else if (access === 'CAN_VIEW' || access === 'CAN_ADD' || access === 'CAN_EDIT' || access === 'CAN_DELETE') {
      access = featureAccess[access];
    } else {
      return false;
    }

    const hasPermission = checkLocationIdPermissions({ permissions, locationId, access });
    if (!hasPermission) {
      return false;
    }
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const checkLocationIdPermissions = ({ permissions, access, locationId }) => {
  try {
    access = shortAccess[access];
    if (locationId && !Array.isArray(locationId)) {
      locationId = [locationId];
    }
    if (access === 'v') {
      if (locationId?.length) {
        const hasPermissions = compareLocationIds({
          permissions: arrayConcat(permissions.v, permissions.a, permissions.e, permissions.d),
          locationId
        });
        return hasPermissions;
      }
      if (permissions.v.length > 0 || permissions.a.length > 0 || permissions.e.length > 0 || permissions.d.length > 0) {
        return true;
      }
    } else if (access === 'a') {
      if (locationId?.length) {
        const hasPermissions = compareLocationIds({ permissions: arrayConcat(permissions.a, permissions.e, permissions.d), locationId });
        return hasPermissions;
      }
      if (permissions.a.length > 0 || permissions.e.length > 0 || permissions.d.length > 0) {
        return true;
      }
    } else if (access === 'e') {
      if (locationId?.length) {
        const hasPermissions = compareLocationIds({ permissions: arrayConcat(permissions.e, permissions.d), locationId });
        return hasPermissions;
      }
      if (permissions.e.length > 0 || permissions.d.length > 0) {
        return true;
      }
    } else if (access === 'd') {
      if (locationId?.length) {
        const hasPermissions = compareLocationIds({ permissions: permissions.d, locationId });
        return hasPermissions;
      }
      if (permissions.d.length > 0) {
        return true;
      }
    }
    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const compareLocationIds = ({ locationId: locationIds, permissions: permissionLocations = [] }) => {
  try {
    if (!locationIds.length && permissionLocations?.length) {
      return false;
    }
    locationIds = locationIds.map((locationId) => parseInt(locationId));
    permissionLocations = permissionLocations.map((locationId) => parseInt(locationId));
    let allLocations = intersection(permissionLocations, locationIds);
    allLocations = allLocations?.sort();
    allLocations = uniqBy(allLocations);
    locationIds = locationIds?.sort();
    locationIds = uniqBy(locationIds);
    return isEqual(allLocations, locationIds);
  } catch (error) {
    console.log(error);
    return false;
  }
};

export default validatePermissions;
