const getJwtToken = () => {
  const userData = localStorage.getItem('lpa');
  if (userData) {
    const { token } = JSON.parse(userData);
    return { JwtToken: `Bearer ${token}` };
  }
  return {};
};

export default getJwtToken;
