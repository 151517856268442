import { NavLink } from 'react-router-dom';
import { HamburgerMenuIcon } from '@radix-ui/react-icons';
import cloudparkLogo from '../../assets/cloudpark_no_text_smaller.png';

export default function NavBar({ handleDrawer, width }) {
  return (
    <div className="h-[60px] bg-white flex items-center justify-between px-4" style={{ width: width || '100%', transition: 'width 0.25s' }}>
      <div className="flex">
        {!!handleDrawer && <HamburgerMenuIcon className="w-9 h-9 cursor-pointer mr-4" onClick={handleDrawer} />}
        {handleDrawer ? (
          <NavLink to="/">
            <img className="h-9 max-w-[66px] cursor-pointer" src={cloudparkLogo} />
          </NavLink>
        ) : (
          <img className="h-9 max-w-[66px] cursor-pointer" src={cloudparkLogo} />
        )}
      </div>
    </div>
  );
}
