import React, { PureComponent, useState, useEffect, Suspense, useContext } from 'react';
import { Switch, Route, useHistory, withRouter } from 'react-router-dom';
import 'firebaseui/dist/firebaseui.css';
import { auth, deviceSignOut } from './services/firebase';
import { Grid, Paper, CircularProgress, Backdrop } from '@mui/material';
import ReportProblemTwoToneIcon from '@mui/icons-material/ReportProblemTwoTone';
import Layout from './components/Layout';
import './App.less';
import './style.less';
import { AppContext } from './context/provider';
import { loadPermissions } from './services/validateUserLogin';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

const RateCalendar = React.lazy(() => import('./components/RateSchedule/RateCalendar'));
const AddLocationCard = React.lazy(() => import('./components/AddLocation/AddLocationCard'));
const ListLocations = React.lazy(() => import('./components/ManageLocations/ListLocations'));
const EditLocation = React.lazy(() => import('./components/ManageLocations/EditLocation'));
const LocationAdded = React.lazy(() => import('./components/AddLocation/LocationAdded'));

const Login = React.lazy(() => import('./components/Auth/Login'));
// const List = React.lazy(() => import('./components/Enforcement/List'));
const List = React.lazy(() => import('./components/EnforcementV2'));
const EditRate = React.lazy(() => import('./components/RateChanges/EditRate'));
const Search = React.lazy(() => import('./components/Enforcement/Search'));
const RateChange = React.lazy(() => import('./components/RateChanges/RateChange'));
const PromoCodes = React.lazy(() => import('./components/PromoCodes/index'));
const NewPromoCode = React.lazy(() => import('./components/PromoCodes/NewPromoCode'));
const RefundPortal = React.lazy(() => import('./components/RefundPortal/index'));
const TicketInfo = React.lazy(() => import('./components/RefundPortal/TicketInfo'));
const RefundTransactions = React.lazy(() => import('./components/RefundPortal/RefundTransactions'));
const RateConfig = React.lazy(() => import('./components/RateConfig'));
const RoleConfig = React.lazy(() => import('./components/RoleConfig'));
const UserConfig = React.lazy(() => import('./components/UserConfig'));
const NoPermissionsDashboard = React.lazy(() => import('./components/Auth/NoPermissionsDashboard'));
const Dashboard = React.lazy(() => import('./components/Dashboard'));
const NavDashboard = React.lazy(() => import('./components/Layout/NavDashboard'));
const Mailing = React.lazy(() => import('./components/Mailing'));
const DatabaseCopy = React.lazy(() => import('./components/DatabaseCopy'));

function App() {
  const [userEmail, setUserEmail] = useState(null);
  const [authEnabled, setAuthEnabled] = useState(false);
  const [loadingPermissions, setLoadingPermissions] = useState(false);
  const [firebaseDone, setFirebaseDone] = useState(false);
  const [currentLocId, setCurrentLocId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [loc, setLoc] = useState('');
  const [locationPriceDescriptors, setLocationPriceDescriptors] = useState([]);
  const [selectedPromoCodeForEdit, setSelectedPromoCodeForEdit] = useState({});
  const [tickets, setTickets] = useState([]);
  const [loginError, setLoginError] = useState(null);
  const history = useHistory();
  const { permissions, setPermissions } = useContext(AppContext);
  const {
    hasEnforcementPermission,
    hasRateSettingsPermission,
    hasPromoCodePermission,
    hasRefundPortalPermission,
    hasUserPermission,
    hasUserRolePermission,
    promoCodePermissions,
    rateSettingsPermissions,
    hasRealTimeDashboardPermission,
    hasSettledDashboardPermission,
    hasLocationsPermissions,
    token: jwtToken,
    superuser
  } = permissions || {};

  useEffect(() => {
    validateLogin();
  }, []);

  useEffect(() => {
    getMyPermissionsData();
  }, [userEmail]);

  const validateLogin = () => {
    auth.onAuthStateChanged(async (user) => {
      setFirebaseDone(true);
      if (window.location.pathname === '/login') {
        return;
      }
      if (user) {
        setIsLoading(false);
        setAuthEnabled(true);
        setUserEmail(user.email);
        document.body.classList.remove('login');
      } else {
        setUserEmail(null);
        setAuthEnabled(false);
        setPermissions(null);
        console.log('we aint got no user');
        logoutFunction();
        console.log(`Firebase did not pass a user object. Logging current user out.`);
      }
    });
  };

  async function getMyPermissionsData() {
    if (!permissions?.token) {
      setLoadingPermissions(true);
    }
    const { data, success } = await loadPermissions({ userEmail });
    setLoadingPermissions(false);
    if (success && data?.userData) {
      const permissions = data?.userData;
      setAuthEnabled(true);
      setPermissions(permissions);
    }
  }

  const editPromoCode = (promoCode) => {
    setSelectedPromoCodeForEdit(promoCode);
  };

  async function logoutFunction() {
    await auth.signOut();
    await deviceSignOut();
    localStorage.removeItem('lpa');
    setUserEmail(null);
    setAuthEnabled(false);
    history.push('/login');
  }

  const fallbackSpinner = () => (
    <Backdrop open>
      <CircularProgress color="primary" />
    </Backdrop>
  );

  if (!firebaseDone) {
    return fallbackSpinner();
  }

  const IS_PROD = window.location.hostname === 'lightningpayadmin.propark.com' ? true : false;

  return (
    <div className="App">
      <ErrorBoundary>
        <Layout userEmail={userEmail} logoutFunction={logoutFunction} authEnabled={authEnabled}>
          <Suspense fallback={fallbackSpinner()}>
            {(firebaseDone && (!authEnabled || loadingPermissions)) || (!permissions?.token && <Redirect to="/login" />)}
            <Switch>
              <Route
                exact
                path="/"
                render={() =>
                  hasRealTimeDashboardPermission ? (
                    <Dashboard isLoggedIn={!!(firebaseDone && jwtToken)} userEmail={userEmail} authEnabled={authEnabled} />
                  ) : authEnabled && hasSettledDashboardPermission ? (
                    <Dashboard isLoggedIn={!!(firebaseDone && jwtToken)} settled />
                  ) : (
                    <NavDashboard isLoggedIn={!!(firebaseDone && jwtToken)} userEmail={userEmail} authEnabled={authEnabled} />
                  )
                }
              />
              <Route
                exact
                path="/search"
                render={(props) => (
                  <>
                    {hasEnforcementPermission && (
                      <Search
                        isLoggedIn={!!(firebaseDone && jwtToken)}
                        {...props}
                        userEmail={userEmail}
                        currentLocId={currentLocId}
                        setCurrentLocId={setCurrentLocId}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                      />
                    )}
                  </>
                )}
              />
              <Route
                exact
                path="/rates"
                render={(props) => (
                  <>
                    {authEnabled && hasRateSettingsPermission ? (
                      <RateChange
                        {...props}
                        userEmail={userEmail}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        loc={loc}
                        setLoc={setLoc}
                        currentLocId={currentLocId}
                        setCurrentLocId={setCurrentLocId}
                        locationPriceDescriptors={locationPriceDescriptors}
                        setLocationPriceDescriptors={setLocationPriceDescriptors}
                        isLoggedIn={!!(firebaseDone && jwtToken)}
                      />
                    ) : (
                      <NoPermissionsDashboard userEmail={userEmail} authEnabled={authEnabled} isLoggedIn={!!(firebaseDone && jwtToken)} />
                    )}
                  </>
                )}
              />
              <Route
                exact
                path="/rates/changes/edit/:locationId?/:rateId?/:currentRsId?"
                render={(props) =>
                  authEnabled && hasRateSettingsPermission && !!rateSettingsPermissions?.canEdit ? (
                    <EditRate
                      {...props}
                      userEmail={userEmail}
                      loc={loc}
                      setLoc={setLoc}
                      currentLocId={currentLocId}
                      setCurrentLocId={setCurrentLocId}
                      locationPriceDescriptors={locationPriceDescriptors}
                      setLocationPriceDescriptors={setLocationPriceDescriptors}
                      isLoggedIn={!!(firebaseDone && jwtToken)}
                    />
                  ) : (
                    <NoPermissionsDashboard userEmail={userEmail} authEnabled={authEnabled} isLoggedIn={!!(firebaseDone && jwtToken)} />
                  )
                }
              />
              <Route
                exact
                path="/promo-codes"
                render={(props) => (
                  <>
                    {authEnabled && hasPromoCodePermission ? (
                      <PromoCodes {...props} isLoggedIn={!!(firebaseDone && jwtToken)} userEmail={userEmail} editPromoCode={editPromoCode} />
                    ) : (
                      <NoPermissionsDashboard userEmail={userEmail} authEnabled={authEnabled} />
                    )}
                  </>
                )}
              />
              <Route
                exact
                path="/promo-codes/:edit?/:promoCodeId?"
                render={(props) => (
                  <>
                    {authEnabled && hasPromoCodePermission && (!!promoCodePermissions?.canEdit || !!promoCodePermissions?.canAdd) ? (
                      <NewPromoCode
                        {...props}
                        userEmail={userEmail}
                        setSelectedPromoCodeForEdit={setSelectedPromoCodeForEdit}
                        selectedPromoCodeForEdit={selectedPromoCodeForEdit}
                        isLoggedIn={!!(firebaseDone && jwtToken)}
                      />
                    ) : (
                      <NoPermissionsDashboard isLoggedIn={!!(firebaseDone && jwtToken)} userEmail={userEmail} authEnabled={authEnabled} />
                    )}
                  </>
                )}
              />
              <Route
                exact
                path="/list"
                render={(props) => (
                  <>
                    {authEnabled && hasEnforcementPermission ? (
                      <List {...props} isLoggedIn={!!(firebaseDone && jwtToken)} userEmail={userEmail} />
                    ) : (
                      <NoPermissionsDashboard isLoggedIn={!!(firebaseDone && jwtToken)} userEmail={userEmail} authEnabled={authEnabled} />
                    )}
                  </>
                )}
              />
              {/* <Route
                exact
                path="/list"
                render={(props) => (
                  <>
                    {authEnabled && hasEnforcementPermission ? (
                      <ListV2 {...props} isLoggedIn={!!(firebaseDone && jwtToken)} userEmail={userEmail} />
                    ) : (
                      <NoPermissionsDashboard isLoggedIn={!!(firebaseDone && jwtToken)} userEmail={userEmail} authEnabled={authEnabled} />
                    )}
                  </>
                )}
              /> */}
              <Route
                exact
                path="/refund-portal/transactions"
                render={(props) => (
                  <>
                    {authEnabled && hasRefundPortalPermission ? (
                      <RefundTransactions {...props} isLoggedIn={!!(firebaseDone && jwtToken)} />
                    ) : (
                      <NoPermissionsDashboard userEmail={userEmail} authEnabled={authEnabled} isLoggedIn={!!(firebaseDone && jwtToken)} />
                    )}
                  </>
                )}
              />
              <Route
                exact
                path="/refund-portal"
                render={(props) => (
                  <>
                    {authEnabled && hasRefundPortalPermission ? (
                      <RefundPortal {...props} setTickets={setTickets} tickets={tickets} isLoggedIn={!!(firebaseDone && jwtToken)} />
                    ) : (
                      <NoPermissionsDashboard userEmail={userEmail} authEnabled={authEnabled} isLoggedIn={!!(firebaseDone && jwtToken)} />
                    )}
                  </>
                )}
              />
              <Route
                exact
                path="/refund-portal/ticket-info/:ticketId"
                render={(props) => (
                  <>
                    {authEnabled && hasRefundPortalPermission ? (
                      <TicketInfo {...props} tickets={tickets} setTickets={setTickets} isLoggedIn={!!(firebaseDone && jwtToken)} />
                    ) : (
                      <NoPermissionsDashboard userEmail={userEmail} authEnabled={authEnabled} />
                    )}
                  </>
                )}
              />
              <Route
                path="/rate-config"
                render={(props) => (
                  <>
                    {authEnabled && hasRateSettingsPermission ? (
                      <RateConfig {...props} isLoggedIn={!!(firebaseDone && jwtToken)} />
                    ) : (
                      <NoPermissionsDashboard userEmail={userEmail} authEnabled={authEnabled} />
                    )}
                  </>
                )}
              />
              <Route
                path="/role-Config"
                render={(props) => (
                  <>
                    {authEnabled && hasUserRolePermission ? (
                      <RoleConfig {...props} isLoggedIn={!!(firebaseDone && jwtToken)} />
                    ) : (
                      <NoPermissionsDashboard userEmail={userEmail} authEnabled={authEnabled} />
                    )}
                  </>
                )}
              />
              <Route
                path="/user-config"
                render={(props) => (
                  <>
                    {authEnabled && hasUserPermission ? (
                      <UserConfig {...props} isLoggedIn={!!(firebaseDone && jwtToken)} />
                    ) : (
                      <NoPermissionsDashboard userEmail={userEmail} authEnabled={authEnabled} />
                    )}
                  </>
                )}
              />
              <Route
                exact
                path="/settled-dashboard"
                render={(props) => (
                  <>
                    {authEnabled && hasSettledDashboardPermission ? (
                      <Dashboard {...props} isLoggedIn={!!(firebaseDone && jwtToken)} settled />
                    ) : (
                      <NoPermissionsDashboard userEmail={userEmail} authEnabled={authEnabled} />
                    )}
                  </>
                )}
              />
              <Route
                exact
                path="/rate-selection"
                render={(props) => (
                  <>
                    {authEnabled && hasRateSettingsPermission ? (
                      <RateCalendar {...props} />
                    ) : (
                      <NoPermissionsDashboard userEmail={userEmail} authEnabled={authEnabled} isLoggedIn={!!(firebaseDone && jwtToken)} />
                    )}
                  </>
                )}
              />
              <Route
                exact
                path="/locations"
                render={(props) => (
                  <>
                    {authEnabled && hasLocationsPermissions ? (
                      <ListLocations {...props} />
                    ) : (
                      <NoPermissionsDashboard userEmail={userEmail} authEnabled={authEnabled} isLoggedIn={!!(firebaseDone && jwtToken)} />
                    )}
                  </>
                )}
              />
              <Route
                exact
                path="/add-location"
                render={(props) => (
                  <>
                    {authEnabled && hasLocationsPermissions ? (
                      <AddLocationCard {...props} />
                    ) : (
                      <NoPermissionsDashboard userEmail={userEmail} authEnabled={authEnabled} isLoggedIn={!!(firebaseDone && jwtToken)} />
                    )}
                  </>
                )}
              />
              <Route
                exact
                path="/add-location/success"
                render={(props) => (
                  <>
                    {authEnabled && hasLocationsPermissions ? (
                      <LocationAdded {...props} />
                    ) : (
                      <NoPermissionsDashboard userEmail={userEmail} authEnabled={authEnabled} isLoggedIn={!!(firebaseDone && jwtToken)} />
                    )}
                  </>
                )}
              />
              <Route
                exact
                path="/edit-location/:locationId"
                render={(props) => (
                  <>
                    {authEnabled && hasLocationsPermissions ? (
                      <EditLocation {...props} />
                    ) : (
                      <NoPermissionsDashboard userEmail={userEmail} authEnabled={authEnabled} isLoggedIn={!!(firebaseDone && jwtToken)} />
                    )}
                  </>
                )}
              />
              <Route
                exact
                path="/mailing"
                render={(props) => (
                  <>
                    {authEnabled && superuser ? (
                      <Mailing {...props} isLoggedIn={!!(firebaseDone && jwtToken)} />
                    ) : (
                      <NoPermissionsDashboard userEmail={userEmail} authEnabled={authEnabled} isLoggedIn={!!(firebaseDone && jwtToken)} />
                    )}
                  </>
                )}
              />
              <Route
                path="/login"
                render={(props) => (
                  <Login
                    {...props}
                    isLoggedIn={!!(firebaseDone && jwtToken)}
                    firebaseDone={firebaseDone}
                    handleLoginSuccess={(email) => setUserEmail(email)}
                    validateLogin={validateLogin}
                    setLoginError={(error) => setLoginError(error)}
                    loginError={loginError}
                    setLoadingPermissions={setLoadingPermissions}
                  />
                )}
              />
              <Route
                path="/database-copy"
                render={(props) => (
                  <>
                    {authEnabled && superuser && !IS_PROD ? (
                      <DatabaseCopy {...props} isLoggedIn={!!(firebaseDone && jwtToken)} />
                    ) : (
                      <NoPermissionsDashboard userEmail={userEmail} authEnabled={authEnabled} />
                    )}
                  </>
                )}
              />
            </Switch>
          </Suspense>
        </Layout>
      </ErrorBoundary>
    </div>
  );
}

class ErrorBoundary extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { errorMessage: '' };
  }

  static getDerivedStateFromError(error) {
    return { errorMessage: error };
  }

  componentDidCatch(error) {
    console.error(error);
  }

  render() {
    if (this.state.errorMessage) {
      return (
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={6}>
            <Paper className="error-boundary">
              <h3 className="error-boundary-header">Hmm. It looks like something went wrong.</h3>
              <ReportProblemTwoToneIcon className="error-boundary-icon" color="error" />
              <div className="error-boundary-footer">
                <p className="error-boundary-footer-text">If this keeps occuring please send an email to:</p>
                <p className="error-boundary-footer-text">jake.conniff@propark.com</p>
              </div>
            </Paper>
          </Grid>
        </Grid>
      );
    }
    return this.props.children;
  }
}

export default withRouter(App);
